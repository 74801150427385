import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import {
  Form,
  useActionData,
  json,
  redirect,
  useLoaderData,
} from '@remix-run/react'
import type { ActionFunction, LoaderFunctionArgs } from '@vercel/remix'
import cx from 'classnames'
import { z } from 'zod'

import logo from '~/assets/images/logo.svg'
import { Button } from '~/components/Button'
import { beginAuthSession } from '~/features/authentication/.server/authSession'
import { decryptMagicLoginToken } from '~/features/authentication/.server/services/magicLoginToken'
import { sendMagicLoginLink } from '~/features/authentication/.server/services/sendMagicLoginLink'
import { contact } from '~/features/email/contacts'
import { findUserByEmail } from '~/features/users/.server/repositories/findUserByEmail'

const loginFormSchema = z.object({
  email: z.string().email(),
  redirectTo: z.string().optional(),
})

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  const token = url.searchParams.get('token')

  if (!token) {
    // 1. unauthenticated user visits the page or was redirected to it
    return json({
      redirectTo: url.searchParams.get('redirectTo'),
    })
  }

  // 3. the magic link redirects to the same url with a token
  let tokenPayload
  try {
    tokenPayload = decryptMagicLoginToken(token)
  } catch (_) {
    return json({
      message:
        'Your magic link seems either expired or malformed. Please try to login again',
    })
  }

  const user = await findUserByEmail(tokenPayload.email)

  if (!user) {
    return json({
      message: `We couldn't find an account with the email ${tokenPayload.email}. Reach out to us if you want to join a program!`,
    })
  }

  const headers = await beginAuthSession(user)

  // 4. Redirect to the page the user wanted to visit or default to the home page
  return redirect(tokenPayload.redirectTo ?? '/', {
    headers,
  })
}

// 2. user submits the form and receives a magic login link
export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData()
  const submission = parseWithZod(formData, { schema: loginFormSchema })

  if (submission.status !== 'success') {
    return json(submission.reply(), 400)
  }

  // Only send emails to known users to prevent damaging email deliverability
  const user = await findUserByEmail(submission.value.email)

  if (user) {
    await sendMagicLoginLink({
      email: submission.value.email,
      redirectTo: submission.value.redirectTo,
    })
  }

  return submission.reply()
}

export default function Login() {
  const data = useLoaderData<typeof loader>()
  const lastResult = useActionData<typeof action>()
  const [form, fields] = useForm({
    lastResult,
    defaultValue: {
      redirectTo: 'redirectTo' in data ? data.redirectTo : null,
    },
    constraint: getZodConstraint(loginFormSchema),
    shouldValidate: 'onBlur',
    shouldRevalidate: 'onInput',
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: loginFormSchema })
    },
  })

  return (
    <div className="flex h-screen items-center">
      <div className="flex h-screen w-full flex-col items-center justify-between bg-neutral-950 px-2.5 lg:w-80 lg:border-r lg:border-neutral-750">
        <div>
          <img
            src={logo}
            alt="Side School logo"
            className="absolute left-0 top-0 mb-8 ml-2.5 mt-3.5"
          />
        </div>
        <div className="flex w-full max-w-sm flex-col items-center px-4">
          <div className="w-full">
            <h1 className="mb-5 font-jakarta text-2xl font-bold text-white">
              Welcome back
            </h1>
          </div>
          {'message' in data ? (
            <p className="text-white">{data.message}</p>
          ) : (
            <>
              {form.status === 'success' ? (
                <div className="w-full">
                  <p className="mb-2.5 text-lg text-neutral-100">
                    Check your emails to sign in.
                  </p>
                  <p className="text-sm text-neutral-200">
                    Haven&apos;t received the email?{' '}
                    <a
                      href={`mailto:${contact}`}
                      className="underline transition-colors hocus:text-white"
                    >
                      Contact us
                    </a>
                    .
                  </p>
                </div>
              ) : (
                <Form method="post" {...getFormProps(form)} className="w-full">
                  <div className="mb-4 font-lato text-sm">
                    <input
                      {...getInputProps(fields.redirectTo, { type: 'hidden' })}
                    />
                    <label htmlFor={fields.email.id} className="sr-only">
                      Email
                    </label>
                    <input
                      {...getInputProps(fields.email, { type: 'email' })}
                      placeholder="my@email.com"
                      autoComplete="email"
                      className={cx(
                        'w-full rounded-md bg-black p-3 text-white  focus:border-neutral-500 focus:outline-none font-medium border-1/2 border-neutral-500',
                        {
                          'border border-red-500 focus:border-red-500':
                            fields.email.errors,
                        }
                      )}
                    />
                    <div
                      id={fields.email.errorId}
                      className="pt-1 text-red-500"
                    >
                      {fields.email.errors}
                    </div>
                  </div>
                  <Button type="submit" className="w-full">
                    Sign in
                  </Button>
                </Form>
              )}
            </>
          )}
        </div>
        <div className="mb-3.5 mt-8 w-full max-w-sm px-4">
          <a
            href="https://www.side.school/"
            className="block w-full rounded-md border-1/2 border-solid border-neutral-500 px-4 py-2 text-center text-sm font-medium text-white hocus:bg-neutral-500"
          >
            Learn more
          </a>
        </div>
      </div>
      <div className="align-center hidden h-screen w-full flex-1 items-center justify-center bg-neutral-900 lg:flex">
        <img src="/logoWithoutText.svg" alt="Side school logo" />
      </div>
    </div>
  )
}
